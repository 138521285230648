import React from "react";
import styles from "./PageNotFound.module.css";
import { Link } from "react-router-dom";

const PageNotFound = ({ pageName }) => {
  return (
    <section className={styles.page_404}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="text-center">
              <div className={styles.four_zero_four_bg}>
                <h1 className="text-center">404</h1>
              </div>
              <div className={styles.contant_box_404}>
                <h3 className="h2">Look like you're lost</h3>
                <small className="d-block text-black pt-2">
                  The page you are looking is not available!
                </small>
                <Link to={pageName} className={styles.link_404}>
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
