import React, { useEffect, useState } from "react";
import styles from "./NYHSForm.module.css";
import InputFields from "../Common/InputField/InputFields";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";
import RadioBtn from "../Common/RadioBtn/RadioBtn";
import { IMAGES } from "../../assets/images/images";
import PhoneNoInput from "../Common/PhoneNoInput/PhoneNoInput";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Swal from 'sweetalert2';
import Loader from "../Common/Loader/Loader";
import { ADD_ROW_TO_SMARTSHEET } from "../../services/URL";


const NyhsForm = () => {
  // State to manage the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    register,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    // employer: "",
    jobTitle: "",
    trade: "",
    phoneNumber: "",
    email: "",
    address: "",
    emergencyContact: "",
    emergencyContactPhone: "",
    gender: "",
    ethnicity: "",
    veteran: "",
    orientation: "",
    fireWatchCard: "",
    profileImageUpload:'',
    weldingCertificate: "",
    weldingCertificateUpload: '',
    sstExpireDate: null,
    fireWatchExpire: null,
    biometricConsent: false,
    sendCopy: false,
  });

  //   const selectedJobRadio = watch("job");
  const [selectedJob, setSelectedJob] = useState("");
  const [isGender, setIsGender] = useState("");
  const [isIdentifyAs, setIsIdentifyAs] = useState("");
  const [isMinority, setIsMinority] = useState("");
  const [isFireWatch, setIsFireWatch] = useState("");
  const [isWelderCertified, setIsWelderCertified] = useState("");
const [showWelderDatePicker, setShowWelderDatePicker] = useState(false);
const [selectedFile, setSelectedFile] = useState(null);
const [isWeldingCertificate, setIsWeldingCertificate] = useState(null)
const [biometricConsent, setBiometricConsent] = useState(false);
const [isLoading, setIsLoading] = useState(false);

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100; 
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
  return formattedDate;
}

  const handleBiometricChange = (event) => {
    setBiometricConsent(event.target.checked);
  };


const weldingCertificateOptions = ["Yes", "No"];

const handleWeldingCertificationChange = (certificationStatus) => {
  setIsWelderCertified(certificationStatus);
  setShowWelderDatePicker(certificationStatus === "Yes");
};

  const jobTitles = [
    "Apprentice",
    "Journeyman",
    "Foreman",
    "Superintendent",
    "Project Management",
    "Engineering",
    "Marketing",
    "Others",
  ];
  const gender = [
    "Male",
    "Female",
    "Other",
  ];
  const minorityoptions = [
    "Yes",
    "No",
  ];

  const identifyasoptions = [
    "Male",
    "Female",
    "Other",
  ];
  
  const fireWatchCard = [
    "Yes",
    "No",
  ];
  
  const handleRadioChange = (jobTitle) => {
    setSelectedJob(jobTitle);
  };
  const handleGenderRadioChange = (gender) => {
    setIsGender(gender);
  };
  const handleIdentifyAsRadioChange = (identifyas) => {
    setIsIdentifyAs(identifyas);
  };
  const handleMinorityChange = (minority) => {
    setIsMinority(minority);
  };
  const handleFireWatchRadioChange = (title) => {
    setIsFireWatch(title);
    if(title.toLowerCase() === 'yes') {
      setShowDatePicker(true);
    }else {
      setShowDatePicker(false);
    }
  };

  
  const onSubmit = (data) => {
    setIsLoading(true); 
    data.jobTitle = selectedJob;
    data.gender = isGender;
    data.identifyas = isIdentifyAs;
    data.fireWatchCard = isFireWatch;
    data.profileImageUpload = selectedFile;
    data.weldingCertificateUpload = isWeldingCertificate;
    data.minority = isMinority;
    data.biometricConsent = biometricConsent;
    data.weldingCertificate = isWelderCertified;
    
    const formData = new FormData();
    formData.append('sheetId', '5707227247628164');
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('emailAddress', data.email);
    formData.append('gender', data.gender);
    formData.append('identifyas', data.identifyas);
    formData.append('minority', data.minority);
    formData.append('sstExpireDate', data.sstExpireDate);
    formData.append('emergencyContactName', data.emergencyContact);
    formData.append('emergencyContactNumber', data.emergencyContactPhone);
    formData.append('doyouhaveaFireWatchCard', data.fireWatchCard);
    formData.append('doyouhaveaWeldingCertificate', data.weldingCertificate);
    formData.append('biometricConcent', data.biometricConsent);
    formData.append('profileImage', data.profileImageUpload);
    formData.append('WeldingCertificateIfapplicable', data.weldingCertificateUpload);
    if (data.fireWatchCard === 'Yes') {
      formData.append('firewatchExpireDate', data.fireWatchExpire);
    }
    if (data.weldingCertificate === 'Yes') {
      formData.append('welderExpireDate', data.weldingCertExpireDate);
    }
    axios.post(ADD_ROW_TO_SMARTSHEET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      setIsLoading(false); 
      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
        });
        setFormData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Form submission failed. Please try again.',
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // Set loading to false in case of error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while submitting the form. Please try again later.',
      });
    });
    reset();
  };
  
  
  const handleUploadProfileImage = (e) => {
    setSelectedFile(e.target.files[0])
  }
  const handleUploadWeldingCertificate = (e) => {
    setIsWeldingCertificate(e.target.files[0]);
  }

  return (
    <div className={`${styles.fromMainwrapper}`}>
      
      <div className={`${styles.fromInnerwrapper}`}>
      {isLoading && <Loader />}
        <div className={`${styles.topSection} mt-3`}>
          <img className="mb-3" src={IMAGES.LogoForm} alt="logo" />
          <p className={`${styles.fontTitle}`}>
            <strong>Turner Construction</strong>
          </p>
          <p className={`${styles.fontTitle}`}>
            <strong>New York Historical Society On-boarding</strong>
          </p>
        </div>
        <section className={`${styles.formWrapper}`}>
          <div className={`${styles.topDis}`}>
            <h2 className={`${styles.AnsBlow}`}>PLEASE ANSWER BELOW</h2>
            <div className={`${styles.textBox}`}>
              <p className={`${styles.subText}`}>
                <p className={`${styles.subText}`}>
                  <strong>
                    Welcome to New York Historical Society Project located at
                    170 Central Park West, New York, NY.
                  </strong>
                </p>
                <p className={`${styles.subText}`}>
                  <strong>
                    The following questions are needed to gain access onto the
                    project.
                  </strong>
                </p>
                <p className={`${styles.subText}`}>
                  <strong>
                    {" "}
                    An ID card reader will be used on this site to gain access
                    to this project{" "}
                  </strong>
                </p>
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-fields">
              <div className={`${styles.topDis}`}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "First name is required",
                    // pattern: {
                    //   value: /^\d{6}$/,
                    //   message: "firstname must be a 6-digit number.",
                    // },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="First Name"
                        placeholder="First Name"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.firstName && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.firstName.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Last name is required",
                    // pattern: {
                    //   value: /^\d{6}$/,
                    //   message: "firstname must be a 6-digit number.",
                    // },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.lastName && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.lastName.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
      <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`}>
        Job Title <span className="fw-bold fs-6 text-danger">*</span>
      </label>
      {jobTitles.map((title) => (
        <RadioBtn
          key={title}
          title={title}
          name="job"
          id={title.toLowerCase().replace(/\s/g, "")}
          value={title.toLowerCase().replace(/\s/g, "")}
          checked={selectedJob === title}
          handleChange={() => handleRadioChange(title)}
        />
      ))}
    </div>

              {/* <div className={`${styles.topDis}`}>
                <Controller
                  name="trade"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Trade is required",
                    // pattern: {
                    //   value: /^\d{6}$/,
                    //   message: "firstname must be a 6-digit number.",
                    // },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Trade"
                        placeholder="Trade"
                        name="trade"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.trade && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.trade.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div> */}
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>Phone Number</label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                      message:
                        "Please enter a valid phone number (+x xxx xxx xxxx).",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        // label="Phone"
                        // CustomIcon={<FaPhone />}
                        type="phone"
                        placeholder="+1 625 999 3488"
                        value={field.value}
                        index="phone"
                        maxLength={65}
                        handleChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                      {errors.phoneNumber && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.phoneNumber.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    // required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="email"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Email Address"
                        placeholder="Email Address"
                        name="email"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        // required
                      />
                      {errors.email && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.email.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              {/* <div className={`${styles.topDis}`}>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Home Address is required",
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Home Address"
                        placeholder="Home Address"
                        name="address"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.address && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.address.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div> */}
              <div className={`${styles.topDis} datee-picker-custom-input`}>
              <Controller
                name="sstExpireDate"
                control={control}
                rules={{
                  required: "SST Expire Date is required",
                }}
                render={({ field }) => (
                  <div className={`${styles.topDis} datee-picker-custom-input`}>
                    <label className={`${styles.labelStyle}`}>
                      SST Expire Date{" "}
                      <span className="fw-bold fs-6 text-danger">*</span>
                    </label>
                    <DatePicker
                      className={`${styles.modalInput} form-control`}
                      selected={field.value} 
                      onChange={(date) => {
                        const formattedDate = formatDate(date);
                        field.onChange(formattedDate); 
                      }}
                      dateFormat="MM/dd/yyyy"
                      showIcon
                      placeholderText="SST Expire Date"
                      icon={<SlCalender />}
                    />
                  </div>
                )}
              />
              </div>
            
              <div className={`${styles.topDis}`}>
                <Controller
                  name="emergencyContact"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Emergency Contact Name"
                        placeholder="Emergency Contact"
                        name="emergencyContact"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      {errors.emergencyContact && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.emergencyContact.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="emergencyContactPhone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Emergency Contact Number"
                        placeholder="Emergency Contact Number"
                        name="emergencyContactPhone"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      {errors.emergencyContactPhone && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.emergencyContactPhone.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>

              <div className={`${styles.topDis}`}>
  <label className={`${styles.labelStyle}`}>Gender</label>
  {gender.map((genderOption) => (
    <RadioBtn
      key={genderOption}
      title={genderOption}
      name="gender"
      id={`gender-${genderOption.toLowerCase().replace(/\s/g, "")}`} // Append "gender-" prefix to make it unique
      value={genderOption.toLowerCase().replace(/\s/g, "")}
      checked={isGender === genderOption}
      handleChange={() => handleGenderRadioChange(genderOption)}
    />
  ))}
</div>

<div className={`${styles.topDis}`}>
  <label className={`${styles.labelStyle}`}>Identify as</label>
  {identifyasoptions.map((identifyasOption) => (
    <RadioBtn
      key={identifyasOption}
      title={identifyasOption}
      name="identifyas"
      id={`identifyas-${identifyasOption.toLowerCase().replace(/\s/g, "")}`} // Append "identifyas-" prefix to make it unique
      value={identifyasOption.toLowerCase().replace(/\s/g, "")}
      checked={isIdentifyAs === identifyasOption}
      handleChange={() => handleIdentifyAsRadioChange(identifyasOption)}
    />
  ))}
</div>

<div className={`${styles.topDis}`}>
  <label className={`${styles.labelStyle}`}>Minority</label>
  {minorityoptions.map((option) => (
    <RadioBtn
      key={option}
      title={option}
      name="minority"
      id={`minority-${option.toLowerCase().replace(/\s/g, "")}`} // Append "minority-" prefix to make it unique
      value={option.toLowerCase()}
      checked={isMinority === option}
      handleChange={() => handleMinorityChange(option)}
    />
  ))}
</div>
              {/* <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>Ethnicity</label>

                <RadioBtn
                  title="White"
                  name="ethnicity"
                  id="white"
                  value="white"
                  checked={isGender === "white"}
                  handleChange={() => handleRadioChange("white")}
                />
                <RadioBtn
                  title="Hispanic"
                  name="ethnicity"
                  id="hispanic"
                  value="white"
                  checked={isGender === "white"}
                  handleChange={() => handleRadioChange("white")}
                />
                <RadioBtn title="Black" name="ethnicity" id="black" />
                <RadioBtn title="Asian" name="ethnicity" id="asian" />
                <RadioBtn
                  title="American Indian"
                  name="ethnicity"
                  id="american"
                />
                <RadioBtn
                  title="Pacific Islander"
                  name="ethnicity"
                  id="islander"
                />
                <RadioBtn title="Other" name="ethnicity" id="others" />
              </div> */}
              {/* <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>Veteran</label>

                <RadioBtn title="Yes" name="veteran" id="yes1" value="yes" />
                <RadioBtn title="No" name="veteran" id="no1" value="no" />
              </div> */}
              {/* <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>Orientation</label>

                <RadioBtn title="Straight" name="orientation" id="straight" />
                <RadioBtn title="lGBTQIA+" name="orientation" id="lGBTQIA" />
              </div> */}
              {/* <div className={`${styles.topDis}`}>
                                <label className={`${styles.labelStyle}`}>
                                    Do you have a Fire Watch Card
                                </label>

                                <RadioBtn title="Yes" name="fire" id="yes" />
                                <RadioBtn title="No" name="fire" id="no" />
                            </div> */}
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Do you have a Fire Watch Card
                </label>
                {fireWatchCard.map((fireWatchCardOptions) => (
        <RadioBtn
          key={fireWatchCardOptions}
          title={fireWatchCardOptions}
          name="fireWatchCard"
          id={`fireWatchCard-${fireWatchCardOptions.toLowerCase().replace(/\s/g, "")}`}
          value={fireWatchCardOptions.toLowerCase().replace(/\s/g, "")}
          checked={isFireWatch === fireWatchCardOptions}
          handleChange={() => handleFireWatchRadioChange(fireWatchCardOptions)}
        />
      ))}
              </div>

              {showDatePicker && (
                <div className={`${styles.topDis}`}>
                 
                  {/* <DatePicker
                    className={`${styles.modalInput} form-control`}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM/dd/yyyy"
                    showIcon
                    placeholderText="SST Expire Date"
                    icon={<SlCalender />}
                  /> */}
                       <Controller
                name="fireWatchExpire"
                control={control}
                rules={{
                  required: "Firewatch expire date is required",
                }}
                render={({ field }) => (
                  <div className={`${styles.topDis} datee-picker-custom-input`}>
                    <label className={`${styles.labelStyle}`}>
                    Firewatch Expire  <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                    <DatePicker
                      className={`${styles.modalInput} form-control`}
                      selected={field.value} // Use field.value for the selected date
                      onChange={(date) => {
                        const formattedDate = formatDate(date);
                        field.onChange(formattedDate); 
                      }}
                      dateFormat="MM/dd/yyyy"
                      showIcon
                      placeholderText="Firewatch expire"
                      icon={<SlCalender />}
                    />
                  </div>
                )}
              />
                </div>
              )}
            <div className={`${styles.topDis}`}>
  <label className={`${styles.labelStyle}`}>
    Do you have a Welding Certificate
  </label>
  {weldingCertificateOptions.map((weldingCertificateOption) => (
    <RadioBtn
      key={weldingCertificateOption}
      title={weldingCertificateOption}
      name="weldingCertificate"
      id={`weldingCertificate-${weldingCertificateOption.toLowerCase().replace(/\s/g, "")}`}
      value={weldingCertificateOption.toLowerCase()}
      checked={isWelderCertified === weldingCertificateOption}
      handleChange={() => handleWeldingCertificationChange(weldingCertificateOption)}
    />
  ))}
</div>

{showWelderDatePicker && (
  <div className={`${styles.topDis}`}>
    <Controller
      name="weldingCertExpireDate"
      control={control}
      rules={{
        required: "Welding certificate expiration date is required",
      }}
      render={({ field }) => (
        <div className={`${styles.topDis} datee-picker-custom-input`}>
          <label className={`${styles.labelStyle}`}>
            Welding Certificate Expire <span className="fw-bold fs-6 text-danger">*</span>
          </label>
          <DatePicker
            className={`${styles.modalInput} form-control`}
            selected={field.value}
            onChange={(date) => {
              const formattedDate = formatDate(date);
              field.onChange(formattedDate); 
            }}
            dateFormat="MM/dd/yyyy"
            showIcon
            placeholderText="Welder expire"
            icon={<SlCalender />}
          />
        </div>
      )}
    />
  </div>
)}
<div className={`${styles.TopBorderWrapper} `}>
  <h2 className={`${styles.AnsBlow}`}>UPLOAD THE FOLLOWING</h2>
  <ol className={`${styles.listUpload}`}>
    <li className={`${styles.listItems}`}>Profile Image</li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="headshotUpload">
        Profile Image Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
          
            {selectedFile ? selectedFile.name : 'Drag and drop files here or'}
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="profileImageUpload"
          name="profileImageUpload"
          onChange={handleUploadProfileImage}
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>SST CARD</li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="sstCardUpload">
        SST Card Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            Drag and drop files here or
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="sstCardUpload"
          name="sstCardUpload"
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>
      Welding Certificate (If Applicable)
    </li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="weldingCertificateUpload">
        Welding Certificate Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            {
              isWeldingCertificate ? isWeldingCertificate.name : 'Drag and drop files here or'
            }
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="weldingCertificateUpload"
          name="weldingCertificateUpload"
          onChange={handleUploadWeldingCertificate}
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>
      Fire Watch Certificate (If Applicable)
    </li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="fireWatchCertificateUpload">
        Fire Watch Certificate Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            Drag and drop files here or
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="fireWatchCertificateUpload"
          name="fireWatchCertificateUpload"
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>
      Scaffold Certificate (If Applicable)
    </li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="scaffoldCertificateUpload">
        Scaffold Certificate Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            Drag and drop files here or
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="scaffoldCertificateUpload"
          name="scaffoldCertificateUpload"
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>
      Scissor Lift Certificate (If Applicable)
    </li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="scissorLiftCertificateUpload">
        Scissor Lift Certificate Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            Drag and drop files here or
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="scissorLiftCertificateUpload"
          name="scissorLiftCertificateUpload"
          className={styles.fileInput}
        />
      </div>
    </div>
    <li className={`${styles.listItems}`}>
      Rigger Certificate (If Applicable)
    </li>
    <div className={`${styles.topDis}`}>
      <label className={`${styles.labelStyle}`} htmlFor="riggerCertificateUpload">
        Rigger Certificate Upload
      </label>
      <div className={styles.fileUploadbox}>
        <div className={`${styles.fileUploadText}`}>
          <span className={`${styles.fileText}`}>
            Drag and drop files here or
            <button tabindex="0" type="button">
              <span> browse files</span>
            </button>
          </span>
        </div>
        <input
          tabindex="0"
          type="file"
          id="riggerCertificateUpload"
          name="riggerCertificateUpload"
          className={styles.fileInput}
        />
      </div>
    </div>
    {/* You can add more items here */}
  </ol>
</div>
              <div className={`${styles.topDis} `}>
                <h2 className={`${styles.AnsBlow}`}>
                  Copy of Sanico USA Biometric Policy
                </h2>

                <a
                  className={`${styles.linkItem}`}
                  href="https://www.sanicousa.com/biometric-privacy-policy"
                >
                  https://www.sanicousa.com/biometric-privacy-policy
                </a>
              </div>
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Biometric Concent
                </label>
                <p className={`${styles.FileText}`}>
                  Checking this box gives your consent to use Biometrics based
                  on the attached policy
                </p>
                <div>
                <input
          className={`${styles.checkBoxCus}`}
          type="checkbox"
          id="biometric"
          name="biometricConcent"
          checked={biometricConsent}
          onChange={handleBiometricChange}
        />                </div>
              </div>
              <div className={`${styles.TopBorderWrapper} `}>
                <div className="pt-3">
                  <input
                    className={`${styles.checkBoxCus}`}
                    type="checkbox"
                    id="biometric"
                    name="biometric"
                    value=""
                  />
                  <label className="ps-2" for="biometric">
                    Send me a copy of my responses
                  </label>
                </div>
                <button
                  type="submit"
                  className={`${styles.submitCustombtn} btn`}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default NyhsForm;
