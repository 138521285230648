import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoUserName = () => {
  return (
    <div>
      <p className="text-black">
        Step One: Please Login to Brivo through valid credentials
      </p>
      <img src={IMAGES.userInfoImage} alt="logged In" width="100%" height="" />
      <p className="text-black mt-3 ">
        Step Two: After login select the desired company
      </p>
      <img src={IMAGES.invSelectComapnyImage} alt="loggedIn" width="100%" />
      <div>
        <p className="mt-3 text-black">
          Step 3: On Side bar click on user profile and copy the user name
        </p>
        <img src={IMAGES.InvUserNameImage} alt="loggedIn" width="100%" />
      </div>
    </div>
  );
};

export default InfoUserName;
