import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Common/Loader/Loader";
import classes from "./FormSettingTable.module.css";

const FormSettingTable = (props) => {
  // const dropdownRef = useRef(null);

  // Effect to handle outside click to close the dropdown
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setActiveDropdownItemId(null);
  //       setIsDropdownOpen(false);
  //     }
  //   }
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const [activeDropdownItemId, setActiveDropdownItemId] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { formListing, handleEditForm, handleDelete } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [skip, setSkip] = useState(10);

  const columns = [
    {
      name: <b>Employer</b>,
      selector: "employerRoles",
      sortable: true,
    },
    {
      name: <b>Actions</b>,
      // selector: row,
      cell: (row) => (
        <div className="d-flex">
          <button
            className="ms-2 btn btn-sm btn-outline-primary"
            onClick={() => {
              handleEditForm(formListing[0]._id, row.index, row.employerRoles);
            }}
          >
            Edit
          </button>
          <button
            className="ms-2 btn btn-event-history-danger btn-sm"
            onClick={() => {
              handleDelete(formListing[0]._id, row.index, row.employerRoles);
            }}
          >
            Delete
          </button>
        </div>
      ),
      width: "180px",
      center: true,
    },
  ];

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePerRowsChange = (s) => {
    setSkip(s);
  };

  return (
    <div className="data-table-wrapper">
      <div className={`${classes.userDataTable}`}>
        <DataTable
          columns={columns}
          responsive
          data={
            formListing &&
            formListing[0]?.employerRoles?.map((employer, index) => ({
              employerRoles: employer,
              index,
            }))
          }
          pagination
          highlightOnHover
          // onSort={}
          onChangeRowsPerPage={(s) => handlePerRowsChange(s)}
          onChangePage={(pageNumber) => onPageChange(pageNumber)}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default FormSettingTable;
