import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoClientId = () => {
  return (
    <div>
      <p className="text-black">Step 1: On sidebar click on configuration</p>
      <img src={IMAGES.incClientIdImage} alt="client id img" />
      <p className="text-black mt-3">
        Step 2: In configuration find and click on Application Management
      </p>
      <img src={IMAGES.invClientStep1Image} alt="client id img" />
      <p className="text-black mt-3">
        Step 3: Check if there are already applications created (remember for
        client id and client click on the application where description is
        password) else create new one (for client id and client secret add the
        “application name” in “description” add password type and in
        “authetication type” check the password box to true
      </p>
      <img src={IMAGES.invMainPage} alt="client id img" />
      <p className="text-black mt-3">
        Step 4: click on the application detail button a popup will appear like this and copy
        the client Id
      </p>
      <img src={IMAGES.invSelectPopupImage} alt="client id img" />
    </div>
  );
};

export default InfoClientId;
