import axios from "axios";
import Compressor from "compressorjs";
import { format } from "date-fns";
import React, { useEffect, useReducer, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { FaCamera } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { IMAGES } from "../../assets/images/images";
import useApi from "../../hooks/useApi";
import {
  ADD_ROW_TO_SMARTSHEET,
  GET_SMART_SHEET_FORM_DETAILS,
  UPLOAD_IMAGE_ON_SERVER
} from "../../services/URL";
import InputFields from "../Common/InputField/InputFields";
import Loader from "../Common/Loader/Loader";
import RadioBtn from "../Common/RadioBtn/RadioBtn";
import CameraComponent from "../OpenCamera/Camera";
import styles from "./SnicoUsaForm.module.css";
import axiosInstance from "../../services/axiosInstance";
import * as Sentry from '@sentry/react';
const initialRadioState = {
  orientation: "",
  orientationError: "",
  jobRole: "",
  jobRoleError: "",
  ethnicity: "",
  ethnicityError: "",
  veteran: "",
  veteranError: "",
  employer: "",
  employerError: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTION":
      return {
        ...state,
        [action.name]: action.value,
        [`${action.name}Error`]: "",
      };
    case "RESET":
      return initialRadioState;
    case "SET_ERROR":
      return {
        ...state,
        [`${action.name}Error`]: action.error,
      };
    default:
      return state;
  }
};
const SnicoUsaForm = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const ID = urlParams.get("id");
  // const specficSheetId1 = "3518628599123844";
  const { apiCall, apiLoading, apiError } = useApi();

  const sheetIds = ["4762372644228996"];
  const searchParams = new URLSearchParams(location.search);
  const searchParamsObj = Object.fromEntries(searchParams.entries());

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    reset,
    register,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const INITIAL_FORM_DATA = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
    email: "",
    homeaddress: "",
    streetAddress: "",
    unit: "",
    city: "",
    state: "",
    zipCode: "",
    emergencyContact: "",
    emergencyContactPhone: "",
    trade: "",
    employer: "",
    gender: "",
    ethnicity: "",
    veteran: "",
    orientation: "",
    fireWatchCard: "",
    profileImageUpload: "",
    weldingCertificate: "",
    weldingCertificateUpload: "",
    SSTCardUpload: "",
    FireWatchCertificateUpload: "",
    ScaffoldCertificateUpload: "",
    ScissorLiftCertificateUpload: "",
    RiggerCertificateUpload: "",
    // orientationDate: null,
    // orientation1YearExpire: null,
    sstIssueDate: null,
    sstExpireDate: null,
    fireWatchExpire: null,
    biometricConsent: false,
    sendCopy: false,
  };

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  //   const selectedJobRadio = watch("job");
  const [selectedJob, setSelectedJob] = useState("");
  const [smartSheetData, setSmartSheetData] = useState({});
  const [isGender, setIsGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [radioSelection, setRadioSelection] = useReducer(
    reducer,
    initialRadioState
  );
  const [isIdentifyAs, setIsIdentifyAs] = useState("");
  const [identifyasError, setidentifyasError] = useState("");
  const [isMinority, setIsMinority] = useState("");
  const [minorityError, setminorityError] = useState("");
  const [isFireWatch, setIsFireWatch] = useState("");
  const [isWelderCertified, setIsWelderCertified] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isWeldingCertificate, setIsWeldingCertificate] = useState(null);
  const [isSSTCardUpload, setIsSSTCardUpload] = useState(null);
  const [isFireWatchCertificateUpload, setIsFireWatchCertificateUpload] =
    useState(null);
  const [isScaffoldCertificateUpload, setIsScaffoldCertificateUpload] =
    useState(null);
  const [isScissorLiftCertificateUpload, setIsScissorLiftCertificateUpload] =
    useState(null);
  const [isRiggerCertificateUpload, setIsRiggerCertificateUpload] =
    useState(null);
  const [biometricConsent, setBiometricConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageSizeError, setprofileImageSizeError] = useState("");
  const [weldingCertificateError, setweldingCertificateError] = useState("");
  const [SSTCardSizeError, setSSTCardSizeError] = useState("");
  const [FireWatchCertificateSizeError, setFireWatchCertificateSizeError] =
    useState("");
  const [ScaffoldCertificateSizeError, setScaffoldCertificateSizeError] =
    useState("");
  const [ScissorLiftCertificateSizeError, setScissorLiftCertificateSizeError] =
    useState("");
  const [RiggerCertificateSizeError, setRiggerCertificateSizeError] =
    useState("");
  const [employer, setEmployer] = useState([
    "Visitor",
    "Sanico USA",
    "United Hoisting & Scaffolding Corp",
    "Turner",
  ]);
  const [ethnicity, setEthnicity] = useState([
    "White",
    "Hispanic",
    "Black",
    "Asian",
    "American Indian",
    "Pacific Islander",
    "Other",
  ]);
  const [gender, setGender] = useState(["Male", "Female", "Other"]);
  const [orientation, setOrientation] = useState([
    "Straight",
    "LGBTQIA+",
    "Other",
  ]);
  const [jobRole, setJobRole] = useState([
    "Apprentice",
    "Journeyman",
    "Foreman",
    "Superintendent",
    "Project Management",
    "Engineering",
    "Marketing",
    "Other",
  ]);
  const [veteran, setVeteran] = useState(["Yes", "No"]);

  const [selfieImage, setSelfieImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);

  const getFormDeatils = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
   
      setIsLoading(true);
      const body = {
        sheetId: searchParamsObj.id,
      };
      const response = await axios.post(GET_SMART_SHEET_FORM_DETAILS, body, {
        headers,
      });
      const {
        formDetails,
        sheetId,
        columns,
        siteName,
        biometricEnabled,
        id,
        companyId,
      } = response.data.data;
      setSmartSheetData({
        sheetId,
        companyId,
        siteId: id,
        sheetColumns: columns,
        siteName,
        biometricEnabled,
        title: formDetails?.title,
        address: formDetails?.address,
      });
      setIsLoading(false);
  };

  
  useEffect(() => {
    const loadData = async () => {
      try {
        await getFormDeatils();
      } catch (error) {
        console.error("An error occurred while loading data:", error);
        Sentry.captureException(error); 
        setIsLoading(false);

        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          text: 'Something went wrong while loading the data. Please try again later.',
        }).then(() => {
          window.location.reload();
        });
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (Object.keys(smartSheetData).length === 0) return;

    // getFormSettingList();

    // Load picklist type data from sheetColumns
    const picklistFields = smartSheetData.sheetColumns.filter(
      (field) => field.type === "PICKLIST"
    );

    const getPicklistFieldsData = picklistFields.reduce((acc, field) => {
      const normalizedTitle = field.title.replace(/\s+/g, "").toLowerCase();
      acc[normalizedTitle] = field.options || [];
      return acc;
    }, {});

    const updatePicklistState = (data) => {
      const stateMap = {
        ethnicity: setEthnicity,
        gender: setGender,
        orientation: setOrientation,
        jobrole: setJobRole,
        employer: setEmployer,
        veteran: setVeteran,
      };

      Object.keys(stateMap).forEach((key) => {
        if (data[key]) stateMap[key](data[key]);
      });
    };

    // Fetch picklist data and update the relevant state
    const picklistFieldsData = getPicklistFieldsData;
    updatePicklistState(picklistFieldsData);
  }, [smartSheetData]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
    return formattedDate;
  }

  const handleGenderRadioChange = (gender) => {
    setIsGender(gender);
    setGenderError("");
  };
  const handleOptionsRadioChange = (option) => {
    const { name, value } = option.target;

    setRadioSelection({ type: "SET_SELECTION", name, value });
  };

  const resetForm = () => {
    reset();
    setSelectedJob(null);
    setIsGender(null);
    setIsIdentifyAs(null);
    setIsMinority(null);
    setIsFireWatch(null);
    setIsWelderCertified(null);
    setSelectedFile(null);
    setIsWeldingCertificate(null);
    setIsSSTCardUpload(null);
    setIsFireWatchCertificateUpload(null);
    setIsScaffoldCertificateUpload(null);
    setIsScissorLiftCertificateUpload(null);
    setIsRiggerCertificateUpload(null);
    setBiometricConsent(false);
    setFormData(INITIAL_FORM_DATA);
    setRadioSelection({ type: "RESET" });
    setSelfieImage(null);
  };

  const onSubmit = async (data) => {
    var hasError = false;
    const radioSelectionKeysToValidate = Object.keys(radioSelection).filter(
      (key) => !key.includes("Error")
    );

    radioSelectionKeysToValidate.forEach((key) => {
      if (!radioSelection[key]) {
        console.log("radioSelection", radioSelection[key]);
        hasError = true;
        setRadioSelection({
          type: "SET_ERROR",
          name: key,
          error: `Please select an option for ${key}`,
        });
      }
    });
    if (!isGender) {
      setGenderError("Please select a gender");
    } else {
      setIsLoading(true);
      data.jobTitle = selectedJob;
      data.gender = isGender;
      data.identifyas = isIdentifyAs;
      data.fireWatchCard = isFireWatch;
      if (selfieImage) {
        const apiResponse = await axios.post(UPLOAD_IMAGE_ON_SERVER, {
          image: selfieImage,
        });

        const image = (apiResponse.data || {}).data;
        if (image) {
          data.profileImageUpload = image.imagePath;
        }
      } else if (selectedFile) {
        data.profileImageUpload = selectedFile;
      }
      data.weldingCertificateUpload = isWeldingCertificate;
      data.SSTCardUpload = isSSTCardUpload;
      data.FireWatchCertificateUpload = isFireWatchCertificateUpload;
      data.ScaffoldCertificateUpload = isScaffoldCertificateUpload;
      data.ScissorLiftCertificateUpload = isScissorLiftCertificateUpload;
      data.RiggerCertificateUpload = isRiggerCertificateUpload;
      data.minority = isMinority;
      data.biometricConsent = biometricConsent;
      data.weldingCertificate = isWelderCertified;
      data.employer = radioSelection.employer;
      data.ethnicity = radioSelection.ethnicity;
      data.veteran = radioSelection.veteran;
      data.orientation = radioSelection.orientation;
      data.jobRole = radioSelection.jobRole;
      const formDataForDefaultSheets = [
        { key: "sheetId", value: smartSheetData.sheetId },
        { key: "firstName", value: data.firstName },
        { key: "lastName", value: data.lastName },
        // { key: "jobTitle", value: data.jobRole },
        { key: "phoneNumber", value: data.phoneNumber },
        { key: "emailAddress", value: data.email },
        { key: "contactList", value: data.email },
        // { key: "trade", value: data.trade },
        { key: "employer", value: data.employer },
        { key: "ethnicity", value: data.ethnicity },
        { key: "veteran", value: data.veteran },
        // { key: "jobRole", value: data.jobRole },
        { key: "homeAddress", value: data.homeaddress },
        { key: "gender", value: data.gender },
        // { key: "identifyas", value: data.identifyas },
        // { key: "minority", value: data.minority },
        data.sstIssueDate && {
          key: "sstIssueDate",
          value: format(new Date(data.sstIssueDate), "yyyy-MM-dd"),
        },
        // data.sstExpireDate && {
        //   key: "sstExpireDate",
        //   value: format(new Date(data.sstExpireDate), "yyyy-MM-dd"),
        // },

        data.orientation && { key: "orientation", value: data.orientation },

        { key: "emergencyContactName", value: data.emergencyContact },
        { key: "emergencyContactNumber", value: data.emergencyContactPhone },
        { key: "doyouhaveaFireWatchCard", value: data.fireWatchCard || "No" },
        {
          key: "doyouhaveaWeldingCertificate",
          value: data.weldingCertificate || "No",
        },
        { key: "biometricConcent", value: data.biometricConsent },
        // Conditional fields
        data.profileImageUpload && {
          key: "profileImage",
          value: data.profileImageUpload,
        },

        data.weldingCertificateUpload && {
          key: "WeldingCertificate",
          value: data.weldingCertificateUpload.registeredFileName,
        },
        data.SSTCardUpload && {
          key: "SSTCard",
          value: data.SSTCardUpload.registeredFileName,
        },
        data.FireWatchCertificateUpload && {
          key: "FireWatchCertificate",
          value: data.FireWatchCertificateUpload.registeredFileName,
        },
        data.ScaffoldCertificateUpload && {
          key: "ScaffoldCertificate",
          value: data.ScaffoldCertificateUpload.registeredFileName,
        },
        data.ScissorLiftCertificateUpload && {
          key: "ScissorLiftCertificate",
          value: data.ScissorLiftCertificateUpload.registeredFileName,
        },
        data.RiggerCertificateUpload && {
          key: "RiggerCertificate",
          value: data.RiggerCertificateUpload.registeredFileName,
        },
      ].filter(Boolean);

      

      const formData = new FormData();

      const FieldsWithFormula = smartSheetData.sheetColumns.filter(
        (field) => field.formula
      );
      // console.log("FieldsWithFormula", FieldsWithFormula);
      const normalizeString = (str) =>
        str.replace(/\s+/g, " ").trim().toLowerCase();

      const formulaFieldTitles = FieldsWithFormula.map((field) =>
        field.title.replace(/\s/g, "").toLowerCase()
      );

      const appendToFormData = (key, value) => {
        const normalizedKey = normalizeString(key);

        if (!formulaFieldTitles.includes(normalizedKey)) {
          formData.append(key, value);
        }
      };
      formDataForDefaultSheets.forEach(({ key, value }) => {
        appendToFormData(key, value);
      });
     
      await axiosInstance
        .post(ADD_ROW_TO_SMARTSHEET, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
             'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          setIsLoading(false);
          if (response.data.status >= 200 && response.data.status < 300) {
            // Check if response.data is not empty
            if (response.data && Object.keys(response.data).length !== 0) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Form submitted successfully!",
              });
              resetForm();
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Form submission failed.",
              });
            }
          } else {
            console.log(response);
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                response?.data?.error?.message ||
                response?.data?.message ||
                "Please check the details and try again",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
          let errorMessage;
          Sentry.captureException(error); //

          // Check if the error has a response property, indicating a server-side error
          if (error.response) {
            errorMessage =
              error.response.data.message || `Error: ${error.response.status}`;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = "No response received from the server.";
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = "Request setup failed.";
          }

          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage,
          });
        });
    }
  };

  const uploadImage = async (file) => {
    // Upload file to server
    const formData = new FormData();
    formData.append("image", file); // 'file' can be changed to the field name your backend expects

    const apiResponse = await apiCall({
      method: "POST",
      url: UPLOAD_IMAGE_ON_SERVER,
      data: formData, // Pass FormData as the data payload
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });


    return { fileName: file.name, registeredFileName: apiResponse.data.imagePath } 
  }

  const compressAndUpload = async (file, setError, setFile) => {
    const maxSize = 2 * 1024 * 1024; // 4 MB

    if (file.size > maxSize) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 3000,
        async success(compressedFile) {
          if (compressedFile.size > maxSize) {
            console.log("compressedFile", compressedFile.size);
            setError(
              "File size exceeds 4 MB limit. Please select a smaller file."
            );
            setFile(null);
          } else {
            const uploadFile = await uploadImage(compressedFile)
            setFile(uploadFile); // Set compressed file
            setError(""); // No error, file is within limit after compression
          }
        },
        error(err) {
          console.error("Compression failed:", err);
          setError("Compression failed. Please try again.");
          setFile(null);
        },
      });
    } else {
      setError(""); // File is already within the size limit

      const uploadFile = await uploadImage(file)
      setFile(uploadFile);
    }
  };

  const handleUploadProfileImage = async  (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setprofileImageSizeError,
      setSelectedFile
    );
    // const file = e.target.files[0];
    // const maxSize = 2 * 1024 * 1024;

    // if (file) {
    //   if (file.size < maxSize) {
    //     // If file size is under 2MB, no need for compression
    //     setprofileImageSizeError("");
    //     setSelectedFile(file);
    //   } else {
    //     new Compressor(file, {
    //       quality: 0.8,
    //       maxWidth: 3000,
    //       success(compressedFile) {
    //         if (compressedFile.size > maxSize) {
    //           setprofileImageSizeError(
    //             "file size exceeds 2 MB limit. Please select a smaller file."
    //           );
    //           e.target.value = null;
    //           setSelectedFile(null);
    //         } else {
    //           setprofileImageSizeError("");
    //           setSelectedFile(compressedFile);
    //         }
    //       },
    //       error(err) {
    //         console.error("Compression failed:", err);
    //       },
    //     });
    //   }
    // }
  };

  // const handlePhotoCapture = async (photoData) => {
  //   const MAX_SIZE_MB = 2;
  //   const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
  //   console.log("🚀 ~ handlePhotoCapture ~ photoData:", photoData)
  //   const photoBlob = convertBase64ToBlob(photoData);
  //   console.log("🚀 ~ handlePhotoCapture ~ photoBlob:", photoBlob)

  //   if (photoBlob.size < MAX_SIZE_BYTES) {
  //     setprofileImageSizeError("");
  //     const uploadFile = await uploadImage(photoBlob)
  //     setSelfieImage(uploadFile); // Set compressed file
  //   } else {
  //     // Compress the photo Blob
  //     new Compressor(photoBlob, {
  //       quality: 0.6,
  //       mimeType: "image/png",
  //       maxWidth: 600,
  //       async success(compressedFile) {
  //         if (compressedFile.size > MAX_SIZE_BYTES) {
  //           console.log("compressedFile", compressedFile.size);
  //           setprofileImageSizeError(
  //             `captured image exceeds ${MAX_SIZE_MB}MB limit.`
  //           );
  //           setSelfieImage(null);
  //         } else {
  //           const uploadFile = await uploadImage(compressedFile)
  //           setSelfieImage(uploadFile); // Set compressed file
  //           setprofileImageSizeError(""); // No error, file is within limit after compression
  //         }
  //       },
  //       error(err) {
  //         console.error("Compression failed:", err);
  //       },
  //     });
  //   }
  // };

  const handlePhotoCapture = (photoData) => {
    const MAX_SIZE_MB = 2;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    const base64Length1 = photoData.length - (photoData.indexOf(",") + 1);
    const imageSizeInBytes = (base64Length1 * 3) / 4;

    if (imageSizeInBytes < MAX_SIZE_BYTES) {
      setprofileImageSizeError("");
      setSelfieImage(photoData);
    } else {
      const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(",")[1]);
        const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };

      const photoBlob = base64ToBlob(photoData);

      // Compress the photo Blob
      new Compressor(photoBlob, {
        quality: 0.6,
        mimeType: "image/png",
        maxWidth: 600,
        success(compressedBlob) {
          // Convert compressed Blob back to base64
          const reader = new FileReader();
          reader.onloadend = () => {
            const compressedBase64 = reader.result;

            // Validate the size of the compressed image
            const compressedBase64Length =
              compressedBase64.length - (compressedBase64.indexOf(",") + 1);
            const compressedImageSizeInBytes = (compressedBase64Length * 3) / 4;

            if (compressedImageSizeInBytes > MAX_SIZE_BYTES) {
              setprofileImageSizeError(
                `captured image exceeds ${MAX_SIZE_MB}MB limit.`
              );
              setSelfieImage(null);
            } else {
              setprofileImageSizeError("");
              setSelfieImage(compressedBase64); // Set the compressed base64 image
            }
          };
          reader.readAsDataURL(compressedBlob); // Convert Blob to base64
        },
        error(err) {
          console.error("Compression failed:", err);
        },
      });
    }
  };
  const handleDropImage = (e, uploadHandler) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    uploadHandler({ target: { files: files } });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleUploadWeldingCertificate = (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setweldingCertificateError,
      setIsWeldingCertificate
    );
    // const maxSize = 10 * 1024 * 1024;

    // if (file && file.size > maxSize) {
    //   setweldingCertificateError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsWeldingCertificate(null);
    // } else {
    //   setweldingCertificateError("");
    //   setIsWeldingCertificate(file);
    // }
  };

  const handleUploadFireWatchCertificate = (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setFireWatchCertificateSizeError,
      setIsFireWatchCertificateUpload
    );
    // const maxSize = 10 * 1024 * 1024;

    // if (file && file.size > maxSize) {
    //   setFireWatchCertificateSizeError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsFireWatchCertificateUpload(null);
    // } else {
    //   setFireWatchCertificateSizeError("");
    //   setIsFireWatchCertificateUpload(file);
    // }
  };
  const handleUploadSSTCard = (e) => {
    const file = e.target.files[0];
    compressAndUpload(file, setSSTCardSizeError, setIsSSTCardUpload);
    // const maxSize = 10 * 1024 * 1024;

    // if (file && file.size > maxSize) {
    //   setSSTCardSizeError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsSSTCardUpload(null);
    // } else {
    //   setSSTCardSizeError("");
    //   setIsSSTCardUpload(file);
    // }
  };
  const handleUploadScaffoldCertificate = (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setScaffoldCertificateSizeError,
      setIsScaffoldCertificateUpload
    );
    // const maxSize = 10 * 1024 * 1024;

    // if (file && file.size > maxSize) {
    //   setScaffoldCertificateSizeError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsScaffoldCertificateUpload(null);
    // } else {
    //   setScaffoldCertificateSizeError("");
    //   setIsScaffoldCertificateUpload(file);
    // }
  };
  const handleUploadScissorLiftCertificate = (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setScissorLiftCertificateSizeError,
      setIsScissorLiftCertificateUpload
    );
    // const maxSize = 10 * 1024 * 1024;
    // if (file && file.size > maxSize) {
    //   setScissorLiftCertificateSizeError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsScissorLiftCertificateUpload(null);
    // } else {
    //   setScissorLiftCertificateSizeError("");
    //   setIsScissorLiftCertificateUpload(file);
    // }
  };
  const handleUploadRiggerCertificate = (e) => {
    const file = e.target.files[0];
    compressAndUpload(
      file,
      setRiggerCertificateSizeError,
      setIsRiggerCertificateUpload
    );
    // const maxSize = 10 * 1024 * 1024;
    // if (file && file.size > maxSize) {
    //   setRiggerCertificateSizeError(
    //     "File size exceeds 10 MB limit. Please select a smaller file."
    //   );
    //   e.target.value = null;
    //   setIsRiggerCertificateUpload(null);
    // } else {
    //   setRiggerCertificateSizeError("");
    //   setIsRiggerCertificateUpload(file);
    // }
  };

  return (
    <div className={styles.fromMainwrapperDefault}>
      <div className={`${styles.fromInnerwrapper}`}>
        {(apiLoading || isLoading) && <Loader />}
        <div className={`${styles.topSection} mt-3`}>
          <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
          <p
            className={`${styles.fontTitle}`}
            style={{ textTransform: "uppercase" }}
          >
            <strong>{smartSheetData.title}</strong>
          </p>
          <p className={`${styles.fontTitle}`}>
            <strong>
              <>
                <span style={{ color: "black" }}>
                  Construction Access Onboarding
                </span>
              </>
            </strong>
          </p>
        </div>
        <section className={`${styles.formWrapper}`}>
          <div className={`${styles.topDis}`}>
            <h2 className={`${styles.AnsBlow} text-center mb-3`}>
              PLEASE ANSWER BELOW
            </h2>
            <div className={`${styles.textBox}`}>
              <p className={`${styles.subText}`}>
                <strong>
                  Welcome to {smartSheetData.siteName} Project located at{" "}
                  {smartSheetData.address}.To gain access to the project site,
                  please answer the following required questions. Multiple ID
                  badge card readers will be used for site access control
                  throughout this project.
                </strong>
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-fields">
              <div className={`${styles.topDis}`}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "First name is required",
                    // pattern: {
                    //   value: /^\d{6}$/,
                    //   message: "firstname must be a 6-digit number.",
                    // },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="First Name"
                        placeholder="First Name"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.firstName && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.firstName.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Last name is required",
                    // pattern: {
                    //   value: /^\d{6}$/,
                    //   message: "firstname must be a 6-digit number.",
                    // },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.lastName && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.lastName.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Job Title{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {jobRole.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="jobRole"
                      id={`jobRole-${option}`} // Append "gender-" prefix to make it unique
                      value={option}
                      checked={radioSelection.jobRole === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.jobRoleError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.jobRoleError}
                    </div>
                  )}
                </div> */}
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Phone Number{" "}
                  <span className="fw-bold fs-6 text-danger">*</span>
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                      message:
                        "Please enter a valid phone number (+x xxx xxx xxxx).",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        // label="Phone"
                        // CustomIcon={<FaPhone />}
                        type="phone"
                        placeholder="+1 625 999 3488"
                        value={field.value}
                        index="phone"
                        maxLength={65}
                        handleChange={(e) => {
                          field.onChange(e);
                        }}
                        required
                      />
                      {errors.phoneNumber && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.phoneNumber.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    // required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net)$/,
                      message:
                        "Invalid email address, only specific domains allowed(.com, .org, .net)",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="email"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Email Address"
                        placeholder="Email Address"
                        name="email"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.email && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.email.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="homeaddress"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Home Address is required",
                  }}
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="homeaddress"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Street Address,Unit,City,State,Zip Code"
                        placeholder="Address"
                        name="homeaddress"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.homeaddress && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.homeaddress.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>

              {/* //Trade */}
              {/* <div className={`${styles.topDis}`}>
                  <Controller
                    name="trade"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Trade is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Trade"
                          placeholder="Trade"
                          name="trade"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.trade && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.trade.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div> */}

              <div className={`${styles.topDis}`}>
                <Controller
                  name="emergencyContact"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Emergency Contact Name"
                        placeholder="Emergency Contact"
                        name="emergencyContact"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.emergencyContact && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.emergencyContact.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className={`${styles.topDis}`}>
                <Controller
                  name="emergencyContactPhone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        type="text"
                        inputStyle={styles.modalInput}
                        labelStyle={styles.labelStyle}
                        label="Emergency Contact Number"
                        placeholder="Emergency Contact Number"
                        name="emergencyContactPhone"
                        value={field.value}
                        handleChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        required
                      />
                      {errors.emergencyContactPhone && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.emergencyContactPhone.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>

              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Gender <span className="fw-bold fs-6 text-danger">*</span>
                </label>
                {gender.map((genderOption) => (
                  <RadioBtn
                    key={genderOption}
                    title={genderOption}
                    name="gender"
                    id={`gender-${genderOption
                      .toLowerCase()
                      .replace(/\s/g, "")}`} // Append "gender-" prefix to make it unique
                    value={genderOption.toLowerCase().replace(/\s/g, "")}
                    checked={isGender === genderOption}
                    handleChange={() => handleGenderRadioChange(genderOption)}
                  />
                ))}

                {genderError && (
                  <div
                    className={`${styles.validationmessage} validation-message`}
                  >
                    {genderError}
                  </div>
                )}
              </div>
              {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Orientation{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {orientation.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="orientation"
                      id={`orientation-${option}`}
                      value={option}
                      checked={radioSelection.orientation === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.orientationError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.orientationError}
                    </div>
                  )}
                </div> */}
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Ethnicity <span className="fw-bold fs-6 text-danger">*</span>
                </label>
                {ethnicity.map((option) => (
                  <RadioBtn
                    key={option}
                    title={option}
                    name="ethnicity"
                    id={`ethnicity-${option}`}
                    value={option}
                    checked={radioSelection.ethnicity === option}
                    handleChange={handleOptionsRadioChange}
                  />
                ))}

                {radioSelection.ethnicityError && (
                  <div
                    className={`${styles.validationmessage} validation-message`}
                  >
                    {radioSelection.ethnicityError}
                  </div>
                )}
              </div>
              <div className={`${styles.topDis}`}>
                <label className={`${styles.labelStyle}`}>
                  Veteran <span className="fw-bold fs-6 text-danger">*</span>
                </label>
                {veteran.map((option) => (
                  <RadioBtn
                    key={option}
                    title={option}
                    name="veteran"
                    id={`veteran-${option}`} // Append "gender-" prefix to make it unique
                    value={option}
                    checked={radioSelection.veteran === option}
                    handleChange={handleOptionsRadioChange}
                  />
                ))}

                {radioSelection.veteranError && (
                  <div
                    className={`${styles.validationmessage} validation-message`}
                  >
                    {radioSelection.veteranError}
                  </div>
                )}
              </div>
              {sheetIds.includes(ID) && orientation.length > 0 && (
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Orientation{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>

                  <select
                    name="orientation"
                    id="orientation"
                    value={radioSelection.orientation}
                    onChange={handleOptionsRadioChange}
                    className="form-select"
                  >
                    <option value="">Select an orientation</option>
                    {orientation.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  {radioSelection.orientationError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.orientationError}
                    </div>
                  )}
                </div>
              )}

              {employer.length > 0 && (
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Employer <span className="fw-bold fs-6 text-danger">*</span>
                  </label>

                  <select
                    name="employer"
                    id="employer"
                    value={radioSelection.employer}
                    onChange={handleOptionsRadioChange}
                    className="form-select"
                  >
                    <option value="">Select an employer</option>
                    {employer.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  {radioSelection.employerError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.employerError}
                    </div>
                  )}
                </div>
              )}

              <div className={`${styles.TopBorderWrapper} `}>
                <h2 className={`${styles.AnsBlow}`}>UPLOAD THE FOLLOWING</h2>
                <p className={`${styles.note}`}>
                  <strong>Note:</strong> File size should be less than 10 MB
                </p>
                {/* <p className={`${styles.note}`}>
                  <strong>Note:</strong>Profile Image should be GIF, PNG, JPG or
                  JPEG with maximum size of 2MB
                </p> */}
                <p className={`${styles.note}`}>
                  <strong>Note:</strong> Only (.png .jpg .gif ) files are
                  accepted
                </p>
                {/* <p className={`${styles.note}`}>
                  <strong>Profile/Badge Image:</strong> Plain background, not
                  hats or sunglasses
                </p> */}
                <ol className={`${styles.listUpload}`}>
                  <li className={`${styles.listItems}`}>
                    <>Profile/Badge Image</>
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) => handleDropImage(e, handleUploadProfileImage)}
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="headshotUpload"
                    >
                      Profile/Badge Image
                      <span className="fw-bold fs-6 text-danger">*</span>
                    </label>
                    <div className={`${styles.profileImageBox}`}>
                      {/* <div className={styles.profileUploadBox}>
                        <div className={`${styles.fileUploadbox}`}>
                          <div className={`${styles.fileUploadText}`}>
                            <span className={`${styles.fileText}`}>
                              {selectedFile?.fileName || "Drag and drop files here or"}
                              <button type="button" tabIndex="0">
                                <span> browse files</span>
                              </button>
                            </span>
                          </div>

                          <input
                            tabIndex="0"
                            type="file"
                            id="profileImageUpload"
                            name="profileImageUpload"
                            accept=".png, .jpg, .gif, .jpeg"
                            onChange={handleUploadProfileImage}
                            disabled={selfieImage || showCamera ? true : false}
                            className={`${styles.fileInput} ${
                              selfieImage ? styles.hidden : ""
                            }`}
                          />
                        </div>
                      </div> */}
                      <div
                        style={{ flex: "1" }}
                        className={`mb-3 ${styles.fileUploadbox} ${styles.profileCameraBox}`}
                      >
                        <FaCamera
                          className={`${styles.cameraIcon} ${
                            selfieImage ? styles.hidden : ""
                          }`}
                          onClick={() => {
                            if (!selectedFile) {
                              setShowCamera(true);
                            }
                          }}
                          tabIndex="0"
                        />
                        <span
                          className="mt-2 fs-7"
                          style={{ color: "rgb(118, 118, 118)" }}
                        >
                          Plain background, no hats or sunglasses
                        </span>
                      </div>
                    </div>

                    {/* Camera Component for taking photo */}
                    {showCamera && !selfieImage && (
                      <CameraComponent
                        onPhotoCapture={(photo) => {
                          handlePhotoCapture(photo); // Capture photo
                          setShowCamera(false); // Hide camera after photo is taken
                        }}
                        setShowCamera={setShowCamera}
                      />
                    )}

                    {/* Validation message for file size error */}
                    {profileImageSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {profileImageSizeError}
                      </div>
                    )}

                    {/* Preview the taken photo */}
                    {selfieImage && (
                      <div
                        className={`${styles.imagePreview} ${styles.imagePreviewBox}`}
                      >
                        <button
                          type="button"
                          className={`${styles.removeBtn}`}
                          onClick={() => {
                            setSelfieImage(null); // Reset the selfie image
                            setSelectedFile(null);
                            setShowCamera(false); // Optionally reset the file
                          }}
                        >
                          <span style={{ color: "red" }}>&#x2717;</span>
                        </button>
                        <img src={selfieImage} alt="Selfie Preview" />
                      </div>
                    )}
                  </div>

                  <li className={`${styles.listItems}`}>SST CARD</li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) => handleDropImage(e, handleUploadSSTCard)}
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="sstCardUpload"
                    >
                      SST Card Upload
                      <span className="fw-bold fs-6 text-danger">*</span>
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isSSTCardUpload?.fileName || "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="sstCardUpload"
                        name="sstCardUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadSSTCard}
                        className={styles.fileInput}
                      />
                    </div>
                    {setSSTCardSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {SSTCardSizeError}
                      </div>
                    )}
                  </div>
                  <li className={`${styles.listItems}`}>SST Issue Date</li>
                  <div
                    className={`${styles.topDis} datee-picker-custom-input mb-3`}
                  >
                    <Controller
                      name="sstIssueDate"
                      control={control}
                      rules={{
                        required: "SST Issue Date is required",
                      }}
                      render={({ field }) => (
                        <div
                          className={`${styles.topDis} datee-picker-custom-input`}
                        >
                          <label className={`${styles.labelStyle}`}>
                            SST Issue Date{" "}
                            <span className="fw-bold fs-6 text-danger">*</span>
                          </label>
                          <DatePicker
                            className={`${styles.modalInput} form-control`}
                            selected={field.value}
                            onChange={(date) => {
                              const formattedDate = formatDate(date);
                              field.onChange(formattedDate);
                            }}
                            dateFormat="yyyy/MM/dd"
                            showIcon
                            placeholderText="SST Issue Date"
                            icon={<SlCalender />}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <li className={`${styles.listItems}`}>
                    Welding Certificate (If Applicable)
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) =>
                      handleDropImage(e, handleUploadWeldingCertificate)
                    }
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="weldingCertificateUpload"
                    >
                      Welding Certificate Upload
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isWeldingCertificate?.fileName || "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="weldingCertificateUpload"
                        name="weldingCertificateUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadWeldingCertificate}
                        className={styles.fileInput}
                      />
                    </div>
                    {weldingCertificateError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {weldingCertificateError}
                      </div>
                    )}
                  </div>
                  <li className={`${styles.listItems}`}>
                    Fire Watch Certificate (If Applicable)
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) =>
                      handleDropImage(e, handleUploadFireWatchCertificate)
                    }
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="fireWatchCertificateUpload"
                    >
                      Fire Watch Certificate Upload
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isFireWatchCertificateUpload?.fileName || "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="fireWatchCertificateUpload"
                        name="fireWatchCertificateUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadFireWatchCertificate}
                        className={styles.fileInput}
                      />
                    </div>
                    {FireWatchCertificateSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {FireWatchCertificateSizeError}
                      </div>
                    )}
                  </div>
                  <li className={`${styles.listItems}`}>
                    Scaffold Certificate (If Applicable)
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) =>
                      handleDropImage(e, handleUploadScaffoldCertificate)
                    }
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="scaffoldCertificateUpload"
                    >
                      Scaffold Certificate Upload
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isScaffoldCertificateUpload?.fileName || "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="scaffoldCertificateUpload"
                        name="scaffoldCertificateUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadScaffoldCertificate}
                        className={styles.fileInput}
                      />
                    </div>
                    {ScaffoldCertificateSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {ScaffoldCertificateSizeError}
                      </div>
                    )}
                  </div>
                  <li className={`${styles.listItems}`}>
                    Scissor Lift Certificate (If Applicable)
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) =>
                      handleDropImage(e, handleUploadScissorLiftCertificate)
                    }
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="scissorLiftCertificateUpload"
                    >
                      Scissor Lift Certificate Upload
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isScissorLiftCertificateUpload?.fileName || "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="scissorLiftCertificateUpload"
                        name="scissorLiftCertificateUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadScissorLiftCertificate}
                        className={styles.fileInput}
                      />
                    </div>
                    {ScissorLiftCertificateSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {ScissorLiftCertificateSizeError}
                      </div>
                    )}
                  </div>
                  <li className={`${styles.listItems}`}>
                    Rigger Certificate (If Applicable)
                  </li>
                  <div
                    className={`${styles.topDis}`}
                    onDrop={(e) =>
                      handleDropImage(e, handleUploadRiggerCertificate)
                    }
                    onDragOver={handleDragOver}
                  >
                    <label
                      className={`${styles.labelStyle}`}
                      htmlFor="riggerCertificateUpload"
                    >
                      Rigger Certificate Upload
                    </label>
                    <div className={styles.fileUploadbox}>
                      <div className={`${styles.fileUploadText}`}>
                        <span className={`${styles.fileText}`}>
                          {isRiggerCertificateUpload?.fileName|| "Drag and drop files here or"}
                          <button tabindex="0" type="button">
                            <span> browse files</span>
                          </button>
                        </span>
                      </div>
                      <input
                        tabindex="0"
                        type="file"
                        id="riggerCertificateUpload"
                        name="riggerCertificateUpload"
                        accept=".png, .jpg, .gif"
                        onChange={handleUploadRiggerCertificate}
                        className={styles.fileInput}
                      />
                    </div>
                    {RiggerCertificateSizeError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {RiggerCertificateSizeError}
                      </div>
                    )}
                  </div>
                </ol>
              </div>
              {smartSheetData.biometricEnabled && (
                <>
                  <div className={`${styles.topDis} `}>
                    <h2 className={`${styles.AnsBlow}`}>
                      Copy of Sanico USA Biometric Policy
                    </h2>

                    <a
                      className={`${styles.linkItem}`}
                      href="https://www.sanicousa.com/biometric-privacy-policy"
                    >
                      https://www.sanicousa.com/biometric-privacy-policy
                    </a>
                  </div>
                </>
              )}
              <div className={`${styles.TopBorderWrapper} `}>
                <button
                  type="submit"
                  disabled={
                    !isGender ||
                    !watch("sstIssueDate") ||
                    !radioSelection.ethnicity ||
                    !radioSelection.veteran ||
                    !radioSelection.employer ||
                    !isSSTCardUpload ||
                    apiLoading ||
                    !(selectedFile || selfieImage)
                  }
                  className={`${styles.submitCustombtn} btn`}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default SnicoUsaForm;
