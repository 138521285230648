// export const ROOT_ROUTE = "/dashboard";
// export const USERS_ROUTE = "/users";
export const LOGIN_ROUTE = "/login";
export const ORIENTATION_LOGIN_ROUTE = "/orientation/login";

// export const SITES_ROUTE = "/sites";
// export const EMPLOYEE_ROUTE = "/employee";
export const COMPANIES_ROUTE = "/companies";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const CONTACT_US_ROUTE = "/contact-us";
export const SMARTSHEETS_ROUTE = "/smartsheets";
export const PAGE_NOT_FOUND_ROUTE = "/page-not-found";
export const SANICO_USA_EMPLOYEE_FORM = "/sanico-usa-employee-form";
export const PACE_UNIVERSITY_EMPLOYEE_FORM = "/pace-university-employee-form";
export const NYHS_EMPLOYEE_FORM = "/nyhs-employee-form";
export const FORM_SETTING_ROUTE = "/form-setting";

export const DASHBOARD_CHART_ROWS = 7;
