import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoInvClientId = () => {
  return (
    <div>
      <p className="text-black">
        Step One: Repeat the same process just like for client Id find or create
        the application and add “inv” after the application just like in the
        image and for authentication type check the invitation box
      </p>
      <img src={IMAGES.invCreateApplicationImage} alt="copy imagee" />
      <p className="text-black mt-3">
        Step Two: After creating or finding the invitation application click on
        application detail button and copy the client Id
      </p>
      <img src={IMAGES.invFindingAppImage} alt="copy imagee" />
    </div>
  );
};

export default InfoInvClientId;
