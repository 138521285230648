import axios from "axios";

function withAPIKeys() {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {
    headers: {},
  };
}

const addPageNumber = (url, params) => {
  let str = "";
  if (params.page) {
    str = `page=${params.page}`;
  }
  if (params.limit) {
    str = `${str}${str ? "&" : ""}limit=${params.limit}`;
  }
  return str ? `${url}?${str}` : url;
};

const parseError = (error) => {
  return error;
  // return JSON.parse(error);
};

export const apiCall = {
  get: (payload) =>
    axios
      .get(payload.url, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  post: (payload) =>
    axios
      .post(
        addPageNumber(payload.url, payload.params),
        payload.params,
        withAPIKeys()
      )
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  put: (payload) =>
    axios
      .post(
        addPageNumber(payload.url, payload.params),
        payload.params,
        withAPIKeys()
      )
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  delete: (payload) =>
    axios
      .post(
        addPageNumber(payload.url, payload.params),
        payload.params,
        withAPIKeys()
      )
      .then((response) => response.data)
      .catch((error) => parseError(error)),
};
