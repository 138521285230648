import { PutObjectCommand } from '@aws-sdk/client-s3';
import axios from 'axios';
import { s3 } from '../Config/aws.config';

// Array to store logs temporarily
let logs = [];
const S3_BUCKET_NAME = "safescan-employees-certificates";

// Function to get user's device information
const getDeviceInfo = () => {
  const deviceInfo = {
    userAgent: navigator.userAgent, // Browser's user agent string
    deviceMemory: navigator.deviceMemory, // Memory available on the device
    hardwareConcurrency: navigator.hardwareConcurrency, // Number of logical processors
  };
  return deviceInfo;
};

// Function to get network speed (only available in modern browsers)
const getNetworkSpeed = () => {
  return new Promise((resolve) => {
    if (navigator.connection) {
      resolve({
        effectiveType: navigator.connection.effectiveType, // e.g., '4g', '3g'
        downlink: navigator.connection.downlink, // Approximate download speed in Mbps
        rtt: navigator.connection.rtt, // Round-trip time in milliseconds
      });
    } else {
      resolve({ error: 'Network info not available' });
    }
  });
};

// Function to get the user's public IP address using a third-party service
const getUserIp = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    return 'IP not available';
  }
};

// Function to log FormData
const logFormData = (formData) => {
  const formDataObject = {};
  formData.forEach((value, key) => {
    formDataObject[key] = value;
  });
  return JSON.stringify(formDataObject);
};

// Function to create log blob and upload to S3
const createLogBlobAndUploadToS3 = async () => {
  let filename;

  const logContent = logs.map((log) => {
    filename = `${log.timestamp} -${log.type}-${log.status || ''}`;
    const logData = log.data ? log.data : '';
    const formattedData = logData instanceof FormData ? logFormData(logData) : logData;
    return `${log.timestamp} - [${log.type}] ${log.url} ${log.status || ''} ${JSON.stringify(formattedData)}\n`;
  }).join('');
  
  const blob = new Blob([logContent], { type: 'text/plain' });
  const buffer = await blob.arrayBuffer(); // Convert Blob to ArrayBuffer

  const params = {
    Bucket: S3_BUCKET_NAME,
    Key: `api_logs/addsmartsheetrow_log_${filename}.txt`,
    Body: buffer,
    ContentType: 'text/plain',
  };

  try {
    console.log('params', params);
    const data = await s3.send(new PutObjectCommand(params));
    console.log('File uploaded successfully:', data);
  } catch (err) {
    console.error('Error uploading file:', err);
  }
};

// Function to log data
const logToBrowser = async (data) => {
  const deviceInfo = getDeviceInfo();
  const networkSpeed = await getNetworkSpeed();
  const userIp = await getUserIp();

  const enrichedData = {
    ...data,
    deviceInfo,
    networkSpeed,
    userIp,
  };

  logs.push(enrichedData);

  console.log(logs, logs.length);
  
  if (logs.length >= 0) {
    createLogBlobAndUploadToS3();
    logs = []; // Clear logs after uploading
  }
};

// Axios Interceptors
const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const logEntry = {
    type: 'Request',
    url: config.url,
    method: config.method,
    data: config.data,
    headers: config.headers,
    timestamp: new Date().toISOString(),
  };
  logToBrowser(logEntry);
  return config;
}, (error) => {
  logToBrowser({ type: 'Request Error', error: error.message, timestamp: new Date().toISOString() });
  return Promise.reject(error);
});

// Response interceptor
axiosInstance.interceptors.response.use((response) => {
  const logEntry = {
    type: 'Response',
    url: response.config.url,
    status: response.status,
    data: response.data,
    timestamp: new Date().toISOString(),
  };
  logToBrowser(logEntry);
  return response;
}, (error) => {
  if (error.response) {
    logToBrowser({
      type: 'Response Error',
      url: error.response.config.url,
      status: error.response.status,
      data: error.response.data,
      timestamp: new Date().toISOString(),
    });
  } else {
    logToBrowser({ type: 'Network Error', error: error.message, timestamp: new Date().toISOString() });
  }
  return Promise.reject(error);
});

export default axiosInstance;
