import React, { useEffect, useRef, useState } from "react";
import styles from "./Camera.module.css";
import { MdCamera } from "react-icons/md";

const CameraComponent = ({ onPhotoCapture, setShowCamera }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  useEffect(() => {
    startCamera();
  }, []);

  const startCamera = () => {
    setIsCameraOn(true);
    videoRef.current.setAttribute("autoplay", "");
    videoRef.current.setAttribute("muted", "");
    videoRef.current.setAttribute("playsinline", "");
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "user" } }) // Use front-facing camera
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraError(
          "Camera access was denied. Please allow camera permissions. Please go to Browser > Settings > Site settings. Under “Permissions,” select Camera or Microphone"
        );
      });
  };

  const takePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const photoData = canvas.toDataURL("image/jpeg");
    onPhotoCapture(photoData);

    // Stop the camera stream
    const stream = video.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop(); // Stop each track
      });
    }

    // Clear the video source and hide video
    videoRef.current.srcObject = null;
    setIsCameraOn(false); // Hide video when photo is taken
  };

  return (
    <>
      <div className={styles.imagePreviewBox}>
        <button
          type="button"
          className={`${styles.removeBtn}`}
          onClick={() => {
            setShowCamera(false); 
          }}
        >
          <span style={{ color: "red" }}>&#x2717;</span>
        </button>
        <video
          ref={videoRef}
          className={styles.haloEffect}
          autoplay
          playsinline
        ></video>
        <button className={styles.cameraIcon} onClick={takePhoto}>
          <MdCamera size="30px" />
        </button>
      </div>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {cameraError && (
        <div className={styles.popup}>
          <p className="text-black">{cameraError}</p>
          <button onClick={() => (
            setCameraError(null),
            setShowCamera(false)
          )}>OK</button>
        </div>
      )}
    </>
  );
};

export default CameraComponent;
