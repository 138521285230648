import CryptoJS from "crypto-js";
import { DASHBOARD_CHART_ROWS } from "../Constant/Index";
const secretKey = process.env.REACT_APP_SECRET_KEY;

export const getCurrentBaseUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ":" + window.location.port : ""
  }`;
};

export const getChartStepSize = (maxVal = 0) =>
  maxVal > DASHBOARD_CHART_ROWS ? Math.ceil(maxVal / DASHBOARD_CHART_ROWS) : 1;

export const getChartMaxRows = (maxVal = 0) =>
  maxVal > DASHBOARD_CHART_ROWS * 2 ? maxVal + 1 : DASHBOARD_CHART_ROWS * 2;

export const copyToClipboard = async (linkToCopy = "") => {
  return await navigator.clipboard.writeText(linkToCopy);
};

export const encryptData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return ciphertext;
};
export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedString); // Parse the decrypted string to JSON
};