import React from "react";
import { FaFunnelDollar } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { IMAGES } from "../../../assets/images/images";
import classes from "./Index.module.css";

const SearchBar = (props) => {
  const { search, handleSort, placeholder } = props;
  const handleSearch = (e) => {
    search(e.target.value);
  };

  return (
    <div className={`${classes.searchBar} `}>
      <div className={`${classes.inputContainer} `}>
        <input
          type="text"
          placeholder={placeholder}
          className={`${classes.searchInput}`}
          onChange={(e) => handleSearch(e)}
          autocomplete="one-time-code"
        />
        {/* <img src={IMAGES.FilterI} alt=""
          className={`${classes.clearIcon} `}
          onClick={handleSort}
        /> */}
        <CiSearch className={`${classes.searchIcon} `} />
      </div>
    </div>
  );
};

export default SearchBar;
