import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userType: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    clearUserType: (state) => {
      state.userType = null;
    },
  },
});

export const { setUserType, clearUserType } = userSlice.actions;

export default userSlice.reducer;
