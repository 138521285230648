import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { FaInfoCircle } from "react-icons/fa";
import InputFields from "../../Common/InputField/InputFields";
import classes from "./addNewCompanyModal.module.css";
import InfoClientId from "./InfoClientId";
import InfoClientSecretKey from "./InfoClientSecretKey";
import InfoInvClientId from "./InfoInvClientId";
import InfoInvClientSecretKey from "./InfoInvClientSecretKey";
import InfoPassword from "./InfoPassword";
import InfoUserName from "./InfoUserName";

const AddNewCompanyModal = (props) => {
  const {
    setIsShowPopup,
    handleClose,
    editUser,
    allSites,
    setAddUser,
    newUser,
    handleSubmit,
    errorMsg,
    firstNameError,
    lastNameError,
    phoneError,
    setPhoneError,
    setLastNameError,
    setFirstNameError,
    setEmailError,
    emailError,
    setErrorMsg,
    users,
    isEdit,
  } = props;
  const [passwordStrength, setPasswordStrength] = useState("");
  const [dropDownDisabled, setDropDownDisabled] = useState(false);
  const [showToolTipModal, setShowToolTipModal] = useState(false);
  const [toolTipType, setToolTipType] = useState(null);
  useLayoutEffect(() => {
    if (Object.keys(editUser).length > 0) {
      setAddUser(editUser);
    }
  }, [editUser]);

  const isDisabled = () => {
    if (
      firstNameError !== "" ||
      lastNameError !== "" ||
      phoneError !== "" ||
      // emailError !== "" ||
      errorMsg !== ""
    ) {
      return true;
    }
    return false;
  };
  // const isEmailValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const isPhoneValid = /^\d+$/;
    const isNameValid = /^[A-Za-z]+$/;
    // let sanitizedValue = "";
    if (value.length === 0) {
      setAddUser({ ...newUser, [name]: "" });
    }
    if (name === "user_name") {
      if (isNameValid.test(value)) {
        setAddUser({ ...newUser, [name]: value });
        setFirstNameError("");
        if (value.length > 50) {
          setFirstNameError(
            "First name must be less than or equal to 50 characters"
          );
        }
      } else {
        setFirstNameError("Only letters accepted");
      }
    } else if (name === "company_name") {
      if (isNameValid.test(value)) {
        setAddUser({ ...newUser, [name]: value });
        setLastNameError("");
        if (value.length > 50) {
          setLastNameError(
            "Last name must be less than or equal to 50 characters"
          );
        }
      } else {
        setLastNameError("Only letters accepted");
      }
    } else if (name === "appEnabled" || name === "biometricEnabled") {
      setAddUser({ ...newUser, [name]: e.target.checked });
    }
    // else if (name === "phone") {
    //   let sanitizedValue = value.replace(/[-e]/gi, "").replace(/[^0-9]/g, "");
    //   setAddUser({ ...newUser, [name]: sanitizedValue });
    //   if (value.length > 15) {
    //     setPhoneError("Mobile number cannot be greater than 15 digits!");
    //   } else if (value.length > 0 && value.length < 7) {
    //     setPhoneError("Mobile number cannot be less than 7 digits!");
    //   } else {
    //     setPhoneError("");
    //   }
    //   if (isPhoneValid.test(sanitizedValue) && sanitizedValue.length >= 15) {
    //     setAddUser({ ...newUser, [name]: sanitizedValue });
    //     // setPhoneError("");
    //   }
    // }
    // else if (name === "email") {
    //   if (!isEmailValid.test(value)) {
    //     setEmailError("Invalid email address!");
    //   } else {
    //     setEmailError("");
    //     const emailExist = users.some((user) => user.email === value);
    //     if (emailExist) {
    //       setEmailError("Email already exists!");
    //     }
    //   }
    //   setAddUser({
    //     ...newUser,
    //     [name]: value.replace(/\s/g, ""),
    //   });
    // }
    else {
      // setAddUser({ ...newUser, [name]: value, emailError: "" });
      setAddUser({ ...newUser, [name]: value });
    }

    if (name === "confirmPassword") {
      if (value === newUser.adminPassword) {
        setErrorMsg("");
      } else if (
        value.length > newUser.adminPassword.length ||
        value.length !== newUser.adminPassword.length
      ) {
        setErrorMsg("Password and Confirm Password must match.");
      }
    } else if (name === "adminPassword") {
      if (value === newUser.confirmPassword) {
        setErrorMsg("");
      } else {
        setErrorMsg("Password and Confirm Password must match.");
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      // Prevent cursor from moving forward when space key is pressed
      e.preventDefault();
    }
  };

  // const handleChangeSite = (e) => {
  //   setAddUser({ ...newUser, siteId: e.target.value });
  //   setDropDownDisabled(true);
  // };

  const hasUpperCase = /[A-Z]/.test(newUser.adminPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newUser.adminPassword);

  const checkPasswordStrength = (password) => {
    if (password === 0) {
      return "";
    } else if (password >= 6 && hasUpperCase && hasSpecialChar) {
      return "Strong";
    } else if (password >= 5) {
      return "Normal";
    } else {
      return "Weak";
    }
  };

  useEffect(() => {
    if (!isEdit && newUser.adminPassword === "") {
      setAddUser({ ...newUser, ["adminPassword"]: "" });
      setPasswordStrength("");
    }
    if (!isEdit && newUser.adminPassword.length > 0) {
      const strength = checkPasswordStrength(newUser.adminPassword.length);
      setPasswordStrength(strength);
    }
  }, [newUser.adminPassword]);

  const firstPasswordStrength = () => {
    const passwordLength = newUser.adminPassword.length;
    if (passwordLength === 0) {
      return classes.passwordGrayDots;
    } else if (passwordLength > 0 && passwordLength < 2) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordGreenDots;
    }
  };

  const secondPasswordStrength = () => {
    const passwordLength = newUser.adminPassword.length;
    if (passwordLength >= 0 && passwordLength < 3) {
      return classes.passwordGrayDots;
    } else if (passwordLength >= 3 && passwordLength < 5) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordGreenDots;
    }
  };

  const thirdPasswordStrength = () => {
    const passwordLength = newUser.adminPassword.length;
    if (passwordLength >= 0 && passwordLength <= 5) {
      return classes.passwordGrayDots;
    } else if (passwordLength > 5 && hasUpperCase && hasSpecialChar) {
      return classes.passwordGreenDots;
    } else if (passwordLength > 5 && hasUpperCase) {
      return classes.passwordOrangeDots;
    } else {
      return classes.passwordOrangeDots;
    }
  };

  // Check if all input fields are valid
  const isFormValid = () => {
    return (
      newUser.adminUsername !== "" &&
      newUser.companyname !== "" &&
      // newUser.ApiKey !== "" &&
      newUser.clientId !== "" &&
      newUser.invitationClientId !== "" &&
      newUser.invitationClientSecret !== "" &&
      newUser.clientSecret !== "" &&
      // newUser.siteId !== "" &&
      newUser.adminPassword !== "" &&
      newUser.confirmPassword !== "" &&
      newUser.adminPassword === newUser.confirmPassword
    );
  };

  const isEditFormValid = () => {
    let isDisabled =
      newUser.adminUsername !== "" &&
      newUser.companyname !== "" &&
      // newUser.ApiKey !== "" &&
      newUser.clientId !== "" &&
      newUser.invitationClientId !== "" &&
      newUser.clientSecret !== "" &&
      newUser.invitationClientSecret !== "" &&
      newUser.adminPassword !== "";
    // newUser.siteId !== ""
    return isDisabled;
  };
  const handleClickToolTip = (type) => {
    setToolTipType(type);
    setShowToolTipModal(true);
  };

  const renderToolTipContent = () => {
    switch (toolTipType) {
      case "userName":
        return <InfoUserName />;
      case "clientId":
        return <InfoClientId />;
      case "clientSecretKey":
        return <InfoClientSecretKey />;
      case "invClientId":
        return <InfoInvClientId />;
      case "invClientSecretKey":
        return <InfoInvClientSecretKey />;
      case "password":
        return <InfoPassword />;
      default:
        return null; // Handle default case or error
    }
  };
  const toolTipModalClose = () => {
    setShowToolTipModal(false);
    setToolTipType(null);
  };
  return (
    <div>
      {showToolTipModal && (
        <Modal show={setShowToolTipModal} onHide={toolTipModalClose} size="lg">
          <Modal.Header className="modal-user" closeButton />
          <Modal.Body>{renderToolTipContent()}</Modal.Body>
        </Modal>
      )}
      {showToolTipModal && <div className="hello position-fixed bg-black" />}
      <Modal
        show={setIsShowPopup}
        onHide={handleClose}
        size="xl"
        className={`${classes.addNewModalMain} user-modal-edit`}
      >
        <Modal.Header className="modal-user" closeButton />
        <Modal.Body className={`${classes.addNewBody}`}>
          <div className={`${classes.addNewUserWrapper}`}>
            <Form onSubmit={handleSubmit}>
              <div className={`${classes.addNewUserForm}`}>
                <h4 className={`${classes.mainHeading} text-center mb-5`}>
                  {!isEdit ? "Add New" : "Edit"} Project
                </h4>
                <div className={`${classes.addUserform} form`}>
                  <div className="row mb-3">
                    <div className="col-lg-4 position-relative">
                      <InputFields
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Username"
                        placeholder="Userame"
                        name="adminUsername"
                        value={newUser.adminUsername}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span
                        className={classes.tooltip}
                        onClick={() => handleClickToolTip("userName")}
                      >
                        <FaInfoCircle color="red" />
                      </span>
                      {/* <span className="text-danger">{firstNameError}</span> */}
                    </div>

                    <div className="col-lg-4">
                      <InputFields
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Project Name"
                        placeholder="Project Name"
                        name="companyname"
                        value={newUser.companyname}
                        handleChange={(e) => handleChange(e)}
                      />
                      {/* <span className="text-danger">{phoneError}</span> */}
                    </div>
                    {/* <div className="col-lg-4">
                      <InputFields
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Api Key"
                        placeholder="Api Key"
                        name="ApiKey"
                        value={newUser.ApiKey}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span className="text-danger">{lastNameError}</span>
                    </div> */}
                    <div className="col-lg-4 position-relative">
                      <InputFields
                        type="password"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Client Id"
                        placeholder="Client Id"
                        name="clientId"
                        // disabled={isEdit}
                        // readonly={isEdit ? "readonly" : ""}
                        value={newUser.clientId}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span
                        className={classes.tooltip}
                        onClick={() => handleClickToolTip("clientId")}
                      >
                        <FaInfoCircle color="red" />
                      </span>
                      {/* <span className="text-danger">{emailError}</span> */}
                    </div>
                  </div>
                  <div className="row row-2">
                    <div
                      className={` mb-3 position-relative ${
                        !isEdit ? "col-lg-4" : "col-lg-6"
                      }`}
                    >
                      <InputFields
                        type="password"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Client Secret Key"
                        placeholder="Client Secret Key"
                        name="clientSecret"
                        // disabled={isEdit}
                        // readonly={isEdit ? "readonly" : ""}
                        value={newUser.clientSecret}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span
                        className={classes.tooltip}
                        onClick={() => handleClickToolTip("clientSecretKey")}
                      >
                        <FaInfoCircle color="red" />
                      </span>
                      {/* <span className="text-danger">{emailError}</span> */}
                    </div>
                    <div
                      className={` mb-3 position-relative ${
                        !isEdit ? "col-lg-4" : "col-lg-6"
                      }`}
                    >
                      <InputFields
                        type="password"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Inv Client Id"
                        placeholder="Inv Client Id"
                        name="invitationClientId"
                        // disabled={isEdit}
                        // readonly={isEdit ? "readonly" : ""}
                        value={newUser.invitationClientId}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span
                        className={classes.tooltip}
                        onClick={() => handleClickToolTip("invClientId")}
                      >
                        <FaInfoCircle color="red" />
                      </span>
                      {/* <span className="text-danger">{emailError}</span> */}
                    </div>
                    <div
                      className={`position-relative ${
                        !isEdit ? "col-lg-4" : "col-lg-6"
                      }`}
                    >
                      <InputFields
                        type="password"
                        inputStyle="modalInput"
                        labelStyle="text-capitalize"
                        label="Inv Client Secret Key"
                        placeholder="Inv Client Secret Key"
                        name="invitationClientSecret"
                        // disabled={isEdit}
                        // readonly={isEdit ? "readonly" : ""}
                        value={newUser.invitationClientSecret}
                        handleChange={(e) => handleChange(e)}
                        handleKeyDown={handleKeyDown}
                      />
                      <span
                        className={classes.tooltip}
                        onClick={() => handleClickToolTip("invClientSecretKey")}
                      >
                        <FaInfoCircle color="red" />
                      </span>
                      {/* <span className="text-danger">{emailError}</span> */}
                    </div>
                    {isEdit && (
                      <div className={`${!isEdit ? "col-lg-6" : "col-lg-6"}`}>
                        <div
                          className={`${classes.passwordFiled} position-relative`}
                        >
                          <InputFields
                            type="password"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Password"
                            id="passwordInput"
                            placeholder=""
                            name="adminPassword"
                            cssClass="pe-4"
                            value={newUser.adminPassword}
                            handleChange={(e) => handleChange(e)}
                            handleKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    )}
                    {!isEdit && (
                      <>
                        <div className="col-lg-6">
                          <div
                            className={`${classes.passwordFiled} position-relative`}
                          >
                            <InputFields
                              type="password"
                              inputStyle="modalInput"
                              labelStyle="text-capitalize"
                              label="Password"
                              id="passwordInput"
                              placeholder=""
                              name="adminPassword"
                              cssClass="pe-4"
                              value={newUser.adminPassword}
                              handleChange={(e) => handleChange(e)}
                              handleKeyDown={handleKeyDown}
                            />
                            <span
                              className={classes.tooltip}
                              onClick={() => handleClickToolTip("password")}
                            >
                              <FaInfoCircle color="red" />
                            </span>

                            <div
                              className="d-flex align-items-start pt-2 justify-content-between font-xs"
                              style={{ columnGap: "5px" }}
                            >
                              <span>
                                Please choose a stronger password. Try a mix of
                                letters, <br /> numbers and symbols.
                              </span>
                              <div
                                className={`${classes.strongPasswordfield} d-flex align-items-center justify-content-end pt-1`}
                              >
                                <span className={firstPasswordStrength()} />
                                <span className={secondPasswordStrength()} />
                                <span className={thirdPasswordStrength()} />
                                <span className={`${classes.strongText} ms-2`}>
                                  {passwordStrength}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className={`${classes.passwordFiled} position-relative`}
                          >
                            <InputFields
                              type="password"
                              inputStyle="modalInput"
                              labelStyle="text-capitalize"
                              label="Confirm Password"
                              placeholder=""
                              name="confirmPassword"
                              cssClass="pe-4"
                              value={newUser.confirmPassword}
                              handleChange={(e) => handleChange(e)}
                              handleKeyDown={handleKeyDown}
                            />
                          </div>
                          <span className="text-danger">{errorMsg}</span>
                        </div>
                      </>
                    )}
                    <div className="col-lg-6">
                      <InputFields
                        type="checkbox"
                        controlId="secureScanEnabledCheckbox"
                        label="Secure Scan App Enabled"
                        name="appEnabled"
                        checked={newUser.appEnabled}
                        handleChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputFields
                        type="checkbox"
                        controlId="secureScanBiometricEnabledCheckbox"
                        label="Biometric Enabled"
                        name="biometricEnabled"
                        checked={newUser.biometricEnabled}
                        handleChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 pt-5 mb-1">
                    <Button
                      variant="primary"
                      className={`${classes.signUpBtn}`}
                      onClick={handleSubmit}
                      // disabled={
                      //   (isEdit ? !isEditFormValid() : !isFormValid()) ||
                      //   isDisabled()
                      // }
                      disabled={!isEdit ? !isFormValid() : !isEditFormValid()}
                    >
                      {!isEdit ? "Save" : "Update"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNewCompanyModal;
